<template>
  <section class="hero is-fullheight">
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Detalle del usuario
                  </h1>
                </div>
              </div>
              <div class="level-right" v-if="!modeEdit">
                <div class="level-item">
                  <button class="button btn_aqua is-normal" @click="modeEdit = true">
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-9">
            <card-user-information
              v-if="!loading"
              ref="cardUserInformationRef"
              :dataUser="dataUser"
              :contractsUser="contractsUser"
              :modeEdit="modeEdit"
              :updateUser="updateUser"
              @cancel-edit="cancelEdit"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CustomerDetailMain',
  components: {
    CardUserInformation: () => import('@/components/Customers/Detail/CardUserInformation.vue')
  },
  data () {
    return {
      modeEdit: false,
      userId: this.$route.params.userId,
      loading: false,
      dataUser: {},
      contractsUser: []
    }
  },
  methods: {
    ...mapActions(['getUserDetail', 'editUser']),
    async getDetail () {
      this.loading = true
      const resp = await this.getUserDetail(this.userId)
      if (resp.success) {
        this.dataUser = resp.user
        this.contractsUser = resp.contracts
      }
      this.loading = false
    },
    cancelEdit () {
      this.modeEdit = false
      this.$refs.cardUserInformationRef.spreadData()
    },
    async updateUser (userInfo) {
      const response = await this.editUser({ userInfo: userInfo, userId: this.userId })
      if (response) {
        await this.getDetail()
        this.modeEdit = false
      }
    }
  },
  beforeMount () {
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
  .button {
    width: 120px;
  }
</style>
